<template>
  <div class="vue-tempalte">

    <h3>{{ $t("sidebar.uploadPhotos") }}</h3>
    <uploader
        v-model="fileList"
        :autoUpload="false"
        @on-change="submit"
        name="upload"
    ></uploader>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";
import Uploader from "vux-uploader-component";

export default {
  name: "UploadPhotosPage",
  components: {Uploader},
  mounted() {
    socialvue.index()
  },
  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    }
  },
  data() {
    return {
      submitted: false,
      fileList: [],
      image: null
    }
  },
  created() {
    // reset login status
  },
  methods: {


    submit() {
      this.getBase64(this.fileList[0].blob).then(
          data => {
            let base64 = data.substr(data.indexOf(',') + 1)
            api
                .post("client/uploadPhoto", {
                  token: this.token,
                  extension: "png",
                  image: base64,
                })
          }
      )
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    uploadImages() {
      api
          .post("customer/login", {
            email: this.email,
            password: this.password
          })
          .then((response) => {
            if (response.data.status) {
              this.$store.dispatch('Setting/authUserAction', response.data)
              this.$store.dispatch('Setting/setCustomerData', response.data.customer)
              this.$router.push({path: '/'})
              this.$router.go(0);
            }

          })
    }
  }
};
</script>


<style>
.vux-uploader .vux-uploader_bd .vux-uploader_files .vux-uploader_file {
  height: 120px !important;
}

.vux-uploader_title {
  display: none !important;
}
</style>
